<!--
 * @Author: SongYijie
 * @Date: 2020-05-11 14:53:44
 * @LastEditors: SongYijie
-->
<template>
  <div class='wrap'>
    <mt-header title="新增申请单">
      <router-link to="" slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <div class="invoice-apply-add">
      <div class="item-wrap">
        <div class="item-line">
          <span>个体户姓名:</span>
          <input class="input" v-model="info.applyUserName" :disabled="selfInfo.applyUserName" :style="{ border: 'none'}" />
        </div>
        <div class="item-line">
          <span>申请金额:</span>
          <input :style="{ flexGrow: 0, width: '25%' }" class="input" v-model="info.amt" /> 元
        </div>
      </div>

      <div class="item-wrap">
        <div class="item-line" :style="{ position: 'relative'}">
          <span>购票方:</span>
          <input
            class="input"
            @input="handleChangeCompany"
            :value="info.providerName"
            placeholder="搜索购票方企业"
            @blur="handleInputBlur"
            @focus="handleInputFocus"
          />
          <div class="reault" v-show="this.showResult && !this.searchLoading && this.searchResult.length > 0">
            <p v-for="(item, index) in this.searchResult" :key="index" @click="handleChooseCompany(item, $event)">{{item.companyName}}</p>
          </div>
          <div class="reault" v-show="this.searchLoading">
            <p>加载中...</p>
          </div>
        </div>
        <div class="item-line">
          <span>识别号:</span>
          <input class="input" v-model="info.sellerOrganCode" />
        </div>
        <div class="item-line">
          <span>地址:</span>
          <input class="input" v-model="info.sellerAddress" />
        </div>
        <div class="item-line">
          <span>电话:</span>
          <input class="input" v-model="info.sellerPhone" />
        </div>
        <div class="item-line">
          <span>开户行:</span>
          <input class="input" v-model="info.sellerBankName" />
        </div>
        <div class="item-line">
          <span>账号:</span>
          <input class="input" v-model="info.sellerBankCard" />
        </div>
      </div>

      <div class="item-wrap">
        <div class="item-line">
          <span>销售方:</span>
          <input class="input" v-model="info.busiRegdName" :disabled="selfInfo.busiRegdName" />
        </div>
        <div class="item-line">
          <span>识别号:</span>
          <input class="input" v-model="info.busiRegdCode" :disabled="selfInfo.busiRegdNo" />
        </div>
        <div class="item-line">
          <span>地址:</span>
          <input class="input" v-model="info.busiAddress" :disabled="selfInfo.busiAddress" />
        </div>
        <div class="item-line">
          <span>电话:</span>
          <input class="input" v-model="info.busiMobile" :disabled="selfInfo.userMobile" />
        </div>
        <div class="item-line">
          <span>开户行:</span>
          <input class="input" v-model="info.busiBankName" :disabled="selfInfo.bankOrgName" />
        </div>
        <div class="item-line">
          <span>账号:</span>
          <input class="input" v-model="info.busiBankCard" :disabled="selfInfo.userBankCard" />
        </div>
      </div>

      <div class="item-wrap">
        <div class="item-line">
          <span>手续费:</span>
          <span>{{`¥ ${info.handlingFee || 0}`}}</span>
        </div>
      </div>

      <div class="item-wrap">
        <label class="show-image" v-for="(item, index) in this.incomeCertificateImg" :key="index">
          <img :src="item + '/original'" alt="收入凭证" class="upload" />
          <span class="close" @click="handleClose(index)">x</span>
        </label>
        <label class="upload" :for="'card-upload'">
          <img src="@assets/images/individualBusiness/upload.png" alt="upload" class="upload" />
          <input
            type="file" 
            class="file" 
            accept="image/*"  
            :ref="'inputRef'"
            :id="'card-upload'" 
            @change="handleUpload"
          >
        </label>
      </div>

      <div class="confrim-btn" @click="throttleHandleConfrim">确认</div>
    </div>
  </div>
</template>

<script>
import { Indicator, Toast } from "mint-ui";
import { uploadCardImage } from "@api/user";
import { getAddInvoiceApply, searchCompany, getCompanyDetail, getSelfEEInfo } from '@api/individualBusiness';
import debounce from '@utils/debounce';
import { throttle } from "@utils/common";
export default {
  name: "invoice-apply-add",
  components: {
  },
  data() {
    return {
      info: {},
      incomeCertificateImg: [],
      searchResult: [],
      searchLoading: false,
      selfInfo: {},
      showResult: true,
      rate: 0
    };
  },
  mounted() {
    getSelfEEInfo().then(res => {
      if (res.code === 200) {
        const { data } = res;
        this.selfInfo = data || {};
        this.info = {
          applyUserName: data.applyUserName,
          busiRegdName: data.busiRegdName,
          busiRegdCode: data.busiRegdNo,
          busiAddress: data.busiAddress,
          busiMobile: data.userMobile,
          busiBankName: data.bankOrgName,
          busiBankCard: data.userBankCard
        }
        this.rate = data.receiptRate
      }
    })
  },
  beforeDestroy() {},
  computed: {},
  watch: {
    'info.amt': function(val) {
      this.info.handlingFee = this.multiple(val, this.rate);
      this.info.amt = String(val).replace(/[^\d.]/g,'');
    }
  },
  methods: {
    multiple(arg1, arg2) {
      return Math.round(arg1 * arg2 * 100) / 100;
    },
    handleClose(index) {
      this.incomeCertificateImg.splice(index, 1)
    },
    handleUpload(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      Indicator.open('收入凭证上传中...');
      let timer = setTimeout(() => {
        Indicator.close();
      }, 20000)
      let imageFormData = new FormData();
      imageFormData.append("uploadImage", file);
      this.$refs.inputRef.value = '';
      uploadCardImage(imageFormData).then(res => {
        Indicator.close();
        if (res.code === 200) {
          clearTimeout(timer);
          this.incomeCertificateImg = [...this.incomeCertificateImg, res.data];
        } else {
          Toast(res.message);
        }
      })
    },
    throttleHandleConfrim: throttle(
      function() {
        this.handleConfrim();
      }, 4000
    ),
    handleConfrim() {
      const values = {
        ...this.info,
        incomeCertificateImg: this.incomeCertificateImg.join(',')
      }
      if (!values.applyUserName) {
        Toast('个体户姓名不能为空');
        return;
      }
      if (!values.amt) {
        Toast('申请金额不能为空');
        return;
      }
      if (!values.providerName) {
        Toast('购票方不能为空');
        return;
      }
      if (!values.sellerOrganCode) {
        Toast('购票方识别号不能为空');
        return;
      }
      if (!values.busiRegdName) {
        Toast('销售方不能为空');
        return;
      }
      if (!values.busiRegdCode) {
        Toast('销售方识别号不能为空');
        return;
      }
      if (!values.incomeCertificateImg) {
        Toast('请上传收入凭证');
        return;
      }
      getAddInvoiceApply(values).then(res => {
        if (res.code === 200) {
          const params = encodeURIComponent(JSON.stringify(values));
          this.$router.replace(`/individual-business/invoice-pay-confirm/${params}`);
        } else {
          Toast(res.message);
        }
      })
    },
    handleChangeCompany(e) {
      const { value } = e.target;
      this.info.providerName = value;
      this.searchLoading = true;
      if (!this.showResult) {
        this.showResult = true;
      }
      this.debounceSearchCompany(value);
    },
    handleSearchCompany(name) {
      searchCompany(name).then(res => {
        this.searchLoading = false;
        if (res.code === 200) {
          if (res.data && res.data.data) {
            this.searchResult = res.data.data;
          } else {
            this.searchResult = [];
          }
        } else {
          this.searchResult = [];
        }
      }).catch(err => {
        this.searchLoading = false;
        throw err;
      })
    },
    handleInputBlur() {
      setTimeout(() => {
        this.showResult = false;
      })
    },
    handleInputFocus() {
      if (!this.showResult) {
        this.showResult = true;
      }
    },
    debounceSearchCompany: debounce(
      function(value) {
        this.handleSearchCompany(value)
      }
      , 1000
    ),
    handleChooseCompany(item, e) {
      if (!this.showResult) {
        this.showResult = true;
      }
      e.stopPropagation();
      const { companyName } = item;
      if (!companyName) {
        return ;
      }
      this.searchResult = [];
      this.info.providerName = companyName;
      getCompanyDetail(companyName).then(res => {
        if (res.code === 200) {
          const { data } = res;
          this.info = {
            ...this.info,
            sellerOrganCode: data.taxNumber,
            sellerAddress: data.address,
            sellerPhone: data.phone
          }
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background-color: #f0f2f5;
}
.invoice-apply-add {
  padding: 2.9333vw 5.3333vw 14.5333vw;
}
.item-wrap {
  width: 100%;
  padding: 8vw 5.3333vw;
  background-color: #ffffff;
  border-radius: 3.2vw;
  box-shadow: 0.4vw 0.4vw 1vw 0.43vw rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-bottom: 3.2vw;
}
.reault {
  border: 1px solid #8e8e8e;
  position: absolute;
  width: 80%;
  left: 20%;
  top: 6.6667vw;
  background-color: #ffffff;
  max-height: 66vw;
  overflow: auto;
  color: rgba(51,51,51,0.8);
  font-size: 3vw;
  > p {
    box-sizing: border-box;
    width: 100%;
    height: 6.6667vw;
    line-height: 6.6667vw;
    margin: 0;
    padding: 0 1.6vw;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 3.8vw;

    & + p {
      border-top: 1px solid #8e8e8e;
    };
  }
}
.item-line {
  font-size: 4vw;
  color: rgba(51,51,51,1);
  & + .item-line  {
    margin-top: 6vw;
  }
  display: flex;
  > span {
    display: inline-block;
    &:nth-of-type(1) {
      white-space: nowrap;
      // width: 33%;
      text-align: left;
      margin-right: 3%;
      color: rgba(51,51,51,0.7);
    }
    &:nth-of-type(2) {
      width: 64%;
      font-weight: 500;
    }
  }

  > .input {
    color: rgba(51,51,51,0.9);
    border: none;
    border-bottom: 1px solid #8e8e8e;
    padding: 0 1.6vw;
    background-color: transparent;
    flex-grow: 2;
    font-weight: 500;
    font-size: 4vw;
    border-radius: 0;
  }
}

.confrim-btn {
  font-size: 4.5333vw;
  font-weight: bold;
  color: rgba(255,255,255,1);
  height: 9.3333vw;
  line-height: 9.3333vw;
  width: 100%;
  background-image: linear-gradient(to bottom right, #2ba2f9, #47e2f8);
  text-align: center;
  border-radius: 4.5333vw;
  margin-top: 5.3333vw;
}
.upload {
  width: 100%;
  height: 100%;
  display: block;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .file {
    display: none;
  }
}
.show-image {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    color: rgba(0, 0, 0, 0.6);
    font-size: 5vw;
    width: 6.6667vw;
    height: 6.6667vw;
    line-height: 6.6667vw;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    text-align: center;
  }
}
</style>